import React from "react";
export default function CustomersSection() {
  return (
    <div className="overflow-hidden">
      <section className="client-new-container my-[30px]">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/hoot.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/whiz.svg"
                alt="gdpr"
                className="w-[100px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ross.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ocean.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/oceanAi.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/db.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/Hecksow.svg"
                alt="gdpr"
                className="w-[130px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/genisis.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/am-ma.png"
                alt="gdpr"
                className="w-auto h-20 "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/rate-gain.png"
                alt="gdpr"
                className="w-auto h-[70px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/tumodo.png"
                alt="gdpr"
                className="brightness-[120%] w-[160px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/hoot.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/whiz.svg"
                alt="gdpr"
                className="w-[100px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ross.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ocean.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/oceanAi.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/db.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/Hecksow.svg"
                alt="gdpr"
                className="w-[130px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/genisis.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/am-ma.png"
                alt="gdpr"
                className="w-auto h-20 "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/rate-gain.png"
                alt="gdpr"
                className="w-auto h-[70px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/tumodo.png"
                alt="gdpr"
                className="brightness-[120%] w-[160px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/hoot.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/whiz.svg"
                alt="gdpr"
                className="w-[100px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ross.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ocean.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/oceanAi.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/db.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/Hecksow.svg"
                alt="gdpr"
                className="w-[130px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/genisis.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/am-ma.png"
                alt="gdpr"
                className="w-auto h-20 "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/rate-gain.png"
                alt="gdpr"
                className="w-auto h-[70px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/tumodo.png"
                alt="gdpr"
                className="brightness-[120%] w-[160px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/hoot.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/whiz.svg"
                alt="gdpr"
                className="w-[100px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ross.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ocean.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/oceanAi.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/db.svg"
                alt="gdpr"
                className="w-[120px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/Hecksow.svg"
                alt="gdpr"
                className="w-[130px]"
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/genisis.svg"
                alt="gdpr"
                className=""
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/am-ma.png"
                alt="gdpr"
                className="w-auto h-20 "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/rate-gain.png"
                alt="gdpr"
                className="w-auto h-[70px] "
              />
            </div>
            <div className="slide">
              <img
                src="https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/tumodo.png"
                alt="gdpr"
                className="brightness-[120%] w-[160px] "
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
