import React, { useEffect, useState } from "react";
import SOCIcon from "../assets/icons/SOC.webp";
import AwsCryptoIcon from "../assets/icons/awsCrypto.jpg";
import uploadIcon from "../assets/images/uploadIcon.png";
import createSignature from "../assets/images/createSignature.png";
import FinishIcon from "../assets/images/FinishIcon.png";
import arrowIcon from "../assets/images/arrow.png";
import GDPRIcon from "../assets/icons/GDPR.png";
import ISOIcon from "../assets/icons/ISOIcon.png";
function CompliancesSection() {
  return (
    <div className="compliances-main-container">
      <div>
        <div className="slider compliances-card">
          <div className="compliances-container-header">Trust, Assured.</div>
          <div className="slide-track">
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img
                    src={SOCIcon}
                    className="compliances-img"
                    style={{
                      width: "200px",
                    }}
                  />
                </div>
              </>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img src={GDPRIcon} className="compliances-img" />
                </div>
              </>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={ISOIcon}
                  className="compliances-img"
                  style={{
                    width: "100px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "20px",
                }}
              >
                <img
                  src={AwsCryptoIcon}
                  className="compliances-img"
                  style={{
                    width: "200px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img
                    src={SOCIcon}
                    className="compliances-img"
                    style={{
                      width: "200px",
                    }}
                  />
                </div>
              </>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img src={GDPRIcon} className="compliances-img" />
                </div>
              </>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={ISOIcon}
                  className="compliances-img"
                  style={{
                    width: "100px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={AwsCryptoIcon}
                  className="compliances-img"
                  style={{
                    width: "200px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img
                    src={SOCIcon}
                    className="compliances-img"
                    style={{
                      width: "200px",
                    }}
                  />
                </div>
              </>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img src={GDPRIcon} className="compliances-img" />
                </div>
              </>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={ISOIcon}
                  className="compliances-img"
                  style={{
                    width: "100px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={AwsCryptoIcon}
                  className="compliances-img"
                  style={{
                    width: "200px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img
                    src={SOCIcon}
                    className="compliances-img"
                    style={{
                      width: "200px",
                    }}
                  />
                </div>
              </>
            </div>
            <div className="slide">
              <>
                <div
                  className="compliances-cards"
                  style={{
                    padding: "0px 40px",
                    marginInline: "10px",
                  }}
                >
                  <img src={GDPRIcon} className="compliances-img" />
                </div>
              </>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={ISOIcon}
                  className="compliances-img"
                  style={{
                    width: "100px",
                  }}
                />
              </div>
            </div>
            <div className="slide">
              <div
                className="compliances-cards"
                style={{
                  padding: "0px 40px",
                  marginInline: "10px",
                }}
              >
                <img
                  src={AwsCryptoIcon}
                  className="compliances-img"
                  style={{
                    width: "200px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompliancesSection;
