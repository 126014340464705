// Button.js

import React from "react";
import "../../src/css/Button.css";

const CommonButton = ({
  onClick,
  label,
  className,
  size,
  variant,
  type = "",
  disabled = false,
}) => {
  // Define a map of size classes
  const sizeClasses = {
    small: "small-size",
    medium: "medium-size",
    large: "large-size",
    extraLarge: "extra-large-size",
  };

  // Define a map of variant classes
  const variantClasses = {
    outlined: "outlined-variant",
    contained: "contained-variant",
    blueOutlined: "outlined-blue",
    blueContainer: "contained-blue",
  };

  const sizeClass = sizeClasses[size] || "medium-size";
  const variantClass = variantClasses[variant] || "contained-variant";

  const buttonClasses = `common-button ${variantClass} ${className}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default CommonButton;
