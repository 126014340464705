import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import CountDownTimer from "./CountDownTimer.tsx";
import "./DealsStickyBar.css";

interface StickyBarProps {
  onClose: () => void;
}

const DealsStickyBar: React.FC<StickyBarProps> = ({ onClose }) => {
  const [currentDeal, setCurrentDeal] = useState("");
  const [currentCouponCode, setCurrentCouponCode] = useState("");

  useEffect(() => {
    const dealDates = [
      {
        name: "Black Friday Sale Is Live Now! Get",
        endDate: new Date("November 29, 2024 23:59:59").getTime(),
        couponCode: "BLACKFRIDAY50",
      },
      {
        name: "Cyber Monday Sale Is Live Now! Get",
        endDate: new Date("December 2, 2024 23:59:59").getTime(),
        couponCode: "CYBERMONDAY50",
      },
      {
        name: "Year End Sale Is Live Now! Get",
        endDate: new Date("December 20, 2024 23:59:59").getTime(),
        couponCode: "YEAREND50",
      },
      {
        name: "New Year Sale Is Live Now! Get",
        endDate: new Date("January 2, 2025 23:59:59").getTime(),
        couponCode: "NEWYEAR50",
      },
    ];

    const updateCurrentDeal = () => {
      const now = new Date().getTime();
      const currentDealData = dealDates.find((deal) => now < deal.endDate);
      if (currentDealData) {
        setCurrentDeal(currentDealData.name);
        setCurrentCouponCode(currentDealData.couponCode);
      } else {
        setCurrentDeal("");
        setCurrentCouponCode("");
      }
    };

    updateCurrentDeal();
    const interval = setInterval(updateCurrentDeal, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const handleClaimClick = () => {
    navigator.clipboard.writeText(currentCouponCode);
    window.location.href = "https://app.legittai.com/signup";
  };

  if (!currentDeal) return null;

  return (
    <AppBar position="sticky" id="deals-sticky-bar">
      <Toolbar id="deals-toolbar">
        <div id="deals-content-wrapper">
          <div id="deals-text-wrapper">
            <Typography variant="h6" component="div" id="deals-heading">
              {currentDeal}
              <span id="deals-discount"> 50% OFF</span>
            </Typography>
            <Typography variant="h6" component="div" id="deals-subtext">
              Use code: <span id="deals-coupon-code">{currentCouponCode}</span>
            </Typography>
          </div>
          <div id="deals-timer-wrapper">
            <button onClick={handleClaimClick} id="deals-button">
              Grab the Deal
            </button>
            <CountDownTimer />
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          id="deals-close-button"
        >
          <Close id="deals-close-icon" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default DealsStickyBar;
