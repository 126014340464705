import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import UpgradeSuccess from "./UpgradeSuccess";
import FailurePopup from "./FailurePopup";
import { CircularProgress, Dialog, DialogContent } from "@mui/material"; // Import Switch from MUI
import AcceptCouponFromUser from "../../../Components/AcceptCouponFromUser";
import { useConfettiTrigger } from "../../../Components/Confetti";

const pricingData = {
  pricing: {
    lifetime: {
      "e-Sign Lite": "$99.99",
      "e-Sign Pro": "$199.99",
      "e-Sign Enterprise": "$499.99",
    },
    monthly: {
      "e-Sign Lite": "$9.99",
      "e-Sign Pro": "$19.99",
      "e-Sign Enterprise": "$99.99",
    },
  },
  features: {
    "e-Sign Lite": [
      "Signatures Per Month: 30",
      "Signed Contracts on Platform: 50",
      "Advanced signer authentication",
      "Real-time updates & Reminders",
      "Send automatic reminders for pending signatures",
      "Enable signing on both desktop and mobile devices",
      "Track document status in real-time",
      "Document status notifications and updates",
      "Secure encryption of signed documents",
    ],
    "e-Sign Pro": [
      "All e-Sign Lite Features included",
      "Signatures Per Month: 50",
      "Signed Contracts on Platform: 100",
      "All Lite features included",
      "AI chat Support",
      "Send documents in bulk to multiple recipients",
      "Document status notifications and updates",
      "Option to add multiple signers in sequence or simultaneously",
      "Template creation for frequently used documents",
    ],
    "e-Sign Enterprise": [
      "All e-Sign Pro Features included",
      "Signatures Per Month: 100",
      "Signed Contracts on Platform: 500",
      "All Pro features",
      "24x7 Live Support",
      "Customizable Signing Workflows",
      "Integration with Enterprise Software",
    ],
  },
};

const PricingCard = ({
  plan,
  price,
  features,
  billingType,
  openPopupForPlan,
  createCheckoutSession, //New
}) => (
  <div className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
    <div className="p-8 sm:p-10">
      <h3 className="text-lg font-semibold text-black">{plan}</h3>
      <p className="mt-4 text-5xl font-semibold text-gray-900 dark:text-gray-200">
        {price}
      </p>
      <p className="text-lg text-gray-500 dark:text-gray-400">
        {billingType === "monthly" ? "/month" : "one time"}
      </p>

      <ul className="mt-6 space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-[20px] w-[20px] text-teal-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              ></path>
            </svg>
            <span className="ml-3 text-[13px] text-gray-600 text-left dark:text-gray-300">
              {feature}
            </span>
          </li>
        ))}
      </ul>

      <button
        className="mt-8 w-full rounded-full bg-black border border-black px-4 py-2.5 text-center text-sm font-semibold text-white hover:bg-white hover:text-black transition cursor-pointer"
        // onClick={() => openPopupForPlan(plan, billingType)}
        onClick={() => createCheckoutSession(plan, billingType)} //New
      >
        Buy Now
      </button>
    </div>
  </div>
);

const PricingCardSection = () => {
  const handleConfettiTrigger = useConfettiTrigger();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openFailurePopup, setOpenFailurePopup] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [billingType, setBillingType] = useState("monthly"); // State for billing type

  const [searchParams] = useSearchParams();
  const paymentSuccess = searchParams.get("payment_success");

  const openPopupForPlan = (plan, period) => {
    setSelectedPlan(plan);
    setSelectedPlanPeriod(period);
    setPopupOpen(true);
  };

  const closePopup = () => setPopupOpen(false);

  const createCheckoutSession = async (planType, planPeriod) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_APIS_BASE_URL}/apis/payment/createCheckoutSession`,
        {
          // planType: selectedPlan,
          // planPeriod: selectedPlanPeriod,//uncomment both planType & planPeriod and comment below planType & planPeriod in case of Pricing pop up applied
          planType,
          planPeriod,
          cancelUrl: `${window.location.href}?payment_success=false`,
          successUrl: `${window.location.href}?payment_success=true`,
        }
      );

      if (response.status === 200) {
        window.location.href = response.data.url;
      } else {
        console.error(
          "Failed to create checkout session:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setIsLoading(false);
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (paymentSuccess === "true") {
      setOpenSuccessPopup(true);
      handleConfettiTrigger();
    } else if (paymentSuccess === "false") {
      setOpenFailurePopup(true);
    }
  }, [paymentSuccess]);

  const renderPricingCards = (billingType, createCheckoutSession) => (
    <div
      key={billingType}
      className={`mx-auto mb-[50px] max-w-[1300px] ${
        billingType === "lifetime"
          ? "bg-gradient-to-l from-gray-200 via-fuchsia-200 to-stone-100 py-[40px] px-[20px] 610sm:px-[100px] rounded-[40px] shadow section-ribbon"
          : "rounded-[40px] border py-[40px] px-[20px] 610sm:px-[100px]"
      }`}
    >
      {billingType === "lifetime" && (
        <div className="ribbon-container">
          <span>Lifetime</span>
        </div>
      )}
      <h3
        className={`text-[10px] 610sm:text-[20px] font-medium capitalize ${
          billingType === "lifetime" ? "text-black" : "text-black"
        }`}
      >
        {billingType === "monthly" && <div>{billingType} billing</div>}
        {billingType === "lifetime" && (
          <div className="text-[20px] 610sm:text-[40px] font-bold text-black">
            Pay once, use forever.
          </div>
        )}
      </h3>
      <div className="grid 768sm:grid-cols-2 grid-cols-1 1024sm:grid-cols-3 gap-8 mt-8">
        {Object.entries(pricingData.pricing[billingType]).map(
          ([plan, price]) => (
            <PricingCard
              key={plan}
              plan={plan}
              price={price}
              features={pricingData.features[plan]}
              billingType={billingType}
              openPopupForPlan={openPopupForPlan}
              createCheckoutSession={createCheckoutSession}
            />
          )
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 px-6 lg:px-8">
      <div className="text-center mb-2">
        <h2 className="text-lg font-medium text-black">Pricing</h2>
      </div>

      {/* Toggle switch to select between Monthly and Lifetime billing */}
      <div className="flex justify-center items-center mb-2">
        <label
          htmlFor="themeSwitcherOne"
          className="bg-[#f5f5f5] shadow-two relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1"
        >
          <input
            type="checkbox"
            name="themeSwitcherOne"
            id="themeSwitcherOne"
            className="sr-only "
            checked={billingType === "lifetime"}
            onChange={() =>
              setBillingType(billingType === "monthly" ? "lifetime" : "monthly")
            }
          />
          <span
            className={`light text-primary bg-gray flex items-center space-x-[6px] mx-1 rounded py-2 px-[18px] text-sm font-medium transition-all duration-300 ${
              billingType === "monthly" ? "bg-blue-500 text-white" : ""
            }`}
          >
            Monthly
          </span>
          <span
            className={`dark text-body-color flex items-center space-x-[6px] mx-1 rounded py-2 px-[18px] text-sm font-medium transition-all duration-300 ${
              billingType === "lifetime" ? "bg-blue-500 text-white" : ""
            }`}
          >
            Lifetime
          </span>
        </label>
      </div>
      {/* Render Pricing Cards based on selected billing type */}
      {renderPricingCards(billingType, createCheckoutSession)}

      {/* {isPopupOpen && (
        <Dialog open={isPopupOpen} onClose={closePopup}>
          <DialogContent>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <AcceptCouponFromUser
                setSelectedPlanPeriod={setSelectedPlanPeriod}
                selectedPlanPeriod={selectedPlanPeriod}
                selectedPlan={selectedPlan}
                closePopup={closePopup}
                createCheckoutSession={createCheckoutSession}
              />
            )}
          </DialogContent>
        </Dialog>
      )} */}

      {/* Success and Failure Popups */}
      <UpgradeSuccess
        isOpen={openSuccessPopup}
        onClose={() => setOpenSuccessPopup(false)}
      />
      <FailurePopup
        isOpen={openFailurePopup}
        onClose={() => setOpenFailurePopup(false)}
      />

      <AcceptCouponFromUser
        isPopupOpen={isPopupOpen}
        setPopupOpen={setPopupOpen}
        closePopup={closePopup}
        selectedPlan={selectedPlan}
        selectedPlanPeriod={selectedPlanPeriod}
        createCheckoutSession={createCheckoutSession}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PricingCardSection;
