import React from "react";

export default function ESignValueSection() {
  const items = [
    {
      id: 1,
      title: "Build And Automate Workflows",
      description:
        "Create extensive and multi-level workflows for collaborators, editors, approvers, and signatories. Enforce workflow rules and ensure compliance using tracking and audit trails.",
      imgSrc:
        "https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/esign/Build-And-Automate-Workflows.jpg",
    },
    {
      id: 2,
      title: "Trust and Security Guaranteed",
      description:
        "Legitt AI Signatures have been meticulously designed to align with top-tier security standards, ensuring they meet the rigorous requirements of ISO 27001, GDPR, and SOC Compliances. Every transaction executed through Legitt AI Signatures is not only legally valid but also adheres to global data protection and security norms.",
      imgSrc:
        "https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/esign/Trust-And-Security-Guaranteed.jpg",
    },
  ];

  return (
    <div className="px-6 md:px-12 lg:px-24">
      <h2 className="text-2xl md:text-3xl lg:text-4xl text-center 700sm:text-left font-bold mb-12">
        Reasons
        <span className="headingunderline mr-[10px]">you need </span>
        to be using e-signatures
      </h2>
      {items.map((item, index) => (
        <div
          key={item.id}
          className="grid grid-cols-1 1171sm:grid-cols-2 gap-4 mb-12"
        >
          <div className="bg-[#f0f0ec] text-black p-6 md:p-10 rounded-[40px] text-center 700sm:text-left flex flex-col justify-center items-start h-full">
            <h3 className="text-2xl md:text-3xl font-bold mt-4 mb-4">
              {item.title}
            </h3>
            <div className="text-base font-normal text-[#4d4d4d]">
              {item.description}
            </div>
          </div>
          <div className="flex justify-center items-center bg-[#eff4f3] rounded-[40px] h-full">
            <img
              src={item.imgSrc}
              alt={item.title}
              className="w-full h-full object-cover rounded-[40px]"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
