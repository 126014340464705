import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import UpgradeSuccess from "./UpgradeSuccess";
import FailurePopup from "./FailurePopup";
import { CircularProgress } from "@mui/material";
import "../../css/PlanPurchasePopup.css";

const pricingData = {
  pricing: {
    lifetime: {
      "e-Sign Lite": "$99.99",
      "e-Sign Pro": "$199.99",
      "e-Sign Enterprise": "$499.99",
    },
    monthly: {
      "e-Sign Lite": "$9.99",
      "e-Sign Pro": "$19.99",
      "e-Sign Enterprise": "$99.99",
    },
  },
  features: {
    "e-Sign Lite": [
      "Signatures Per Month: 30",
      "Signed Contracts on Platform: 50",
      "Advanced signer authentication",
      "Real time updates & Reminders",
    ],
    "e-Sign Pro": [
      "Signatures Per Month: 50",
      "Signed Contracts on Platform: 100",
      "All Lite features",
      "AI chat Support",
    ],
    "e-Sign Enterprise": [
      "Signatures Per Month: 100",
      "Signed Contracts on Platform: 500",
      "All Pro features",
      "24x7 Live Support",
    ],
  },
};

const PricingCard = ({
  plan,
  price,
  features,
  billingType,
  isLoading,
  selectedPlan,
  selectedPlanPeriod,
  createCheckoutSession,
}) => (
  <div className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
    <div className="p-4">
      <h3 className="text-lg font-semibold text-black">{plan}</h3>
      <span className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-200">
        {price}
      </span>
      <span className="ml-1 text-sm text-gray-500 dark:text-gray-400">
        {billingType === "monthly" ? "/month" : "one time"}
      </span>
        <ul className="mt-6 space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6 text-teal-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                ></path>
              </svg>
              <span className="ml-3 text-[13px] text-gray-600 text-left dark:text-gray-300">
                {feature}
              </span>
            </li>
          ))}
        </ul>
      
      <button
        className="mt-8 w-full rounded-full bg-black border border-black px-4 py-2.5 text-center text-sm font-semibold text-white hover:bg-white hover:text-black transition cursor-pointer"
        onClick={() => createCheckoutSession(plan, billingType)}
        disabled={
          isLoading &&
          selectedPlan === plan &&
          selectedPlanPeriod === billingType
        }
      >
        {isLoading &&
        selectedPlan === plan &&
        selectedPlanPeriod === billingType ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          "Buy Now"
        )}
      </button>
    </div>
  </div>
);

export default function UpgradePricing() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openFailurePopup, setOpenFailurePopup] = useState(false);
  const [billingType, setBillingType] = useState("lifetime"); // state for the toggle switch

  const [searchParams] = useSearchParams();
  const paymentSuccess = searchParams.get("payment_success");

  const createCheckoutSession = async (planType, planPeriod) => {
    try {
      setSelectedPlan(planType);
      setSelectedPlanPeriod(planPeriod);
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_APIS_BASE_URL}/apis/payment/createCheckoutSession`,
        {
          planType,
          planPeriod,
          cancelUrl: `${window.location.href}?payment_success=false`,
          successUrl: `${window.location.href}?payment_success=true`,
        }
      );

      if (response.status === 200) {
        window.location.href = response.data.url;
      } else {
        console.error(
          "Failed to create checkout session:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentSuccess === "true") {
      setOpenSuccessPopup(true);
    } else if (paymentSuccess === "false") {
      setOpenFailurePopup(true);
    }
  }, [paymentSuccess]);

  const renderPricingCards = (billingType) => (
    <div
      key={billingType}
      className={`mx-auto max-w-[1300px] ${
        billingType === "lifetime"
          ? "bg-gradient-to-l from-gray-200 via-fuchsia-200 to-stone-100 py-[10px] px-[20px] rounded-[40px] shadow section-ribbon"
          : "rounded-[40px] border py-[20px] px-[20px]"
      }`}
    >
      {billingType === "lifetime" && (
        <div className="ribbon-container">
          <span>Lifetime</span>
        </div>
      )}
      <h3
        className={`text-[10px] 610sm:text-[20px] font-medium capitalize ${
          billingType === "lifetime" ? "text-black" : "text-black"
        }`}
      >
        {billingType === "lifetime" && (
          <div className="text-[16px] mb-[20px] 610sm:text-[20px] text-center font-bold text-black">
            Pay once, use forever.
          </div>
        )}
      </h3>
      <div className="grid 768sm:grid-cols-2 grid-cols-1 1024sm:grid-cols-3 gap-4">
        {Object.entries(pricingData.pricing[billingType]).map(
          ([plan, price]) => (
            <PricingCard
              key={plan}
              plan={plan}
              price={price}
              features={pricingData.features[plan]}
              billingType={billingType}
              isLoading={isLoading}
              selectedPlan={selectedPlan}
              selectedPlanPeriod={selectedPlanPeriod}
              createCheckoutSession={createCheckoutSession}
            />
          )
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800">
      <div className="flex justify-center my-2">
      <div className="flex items-center space-x-4">
            <span className="text-gray-700 dark:text-gray-300">Monthly</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={billingType === "lifetime"}
                onChange={() =>
                  setBillingType(
                    billingType === "monthly" ? "lifetime" : "monthly"
                  )
                }
              />
              <div className="group peer ring-0 bg-[#d2d2d2] rounded-full outline-none duration-300 after:duration-300 w-12 h-6  peer-checked:bg-emerald-500 peer-focus:outline-none  after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-5 after:w-5 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
            </label>
            <span className="text-gray-700 dark:text-gray-300">Lifetime</span>
          </div>
      </div>
      {renderPricingCards(billingType)}
      <UpgradeSuccess
        openSuccessPopup={openSuccessPopup}
        setOpenSuccessPopup={setOpenSuccessPopup}
      />
      <FailurePopup
        openFailurePopup={openFailurePopup}
        setOpenFailurePopup={setOpenFailurePopup}
      />
    </div>
  );
}
