import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import FailedGIF from "../../assets/images/FailedGIF.gif";
import { DialogTitle } from "@mui/material";
export default function FailurePopup({
  openFailurePopup,
  setOpenFailurePopup,
}) {
  return (
    <div>
      <Dialog
        open={openFailurePopup}
        className="email-popup-upgrade success-popup"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <CloseIcon
            onClick={() => setOpenFailurePopup(false)}
            style={{
              position: "absolute",
              right: "20px",
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div className="success-popup-content">
            <div
              className="success-plan-purchase-gif-container"
              style={{
                width: "400px",
                height: "400px",
              }}
            >
              <img
                src={FailedGIF}
                alt="Failed GIF"
                className="failed-popup-gif"
              />
            </div>
            <div className="success-popup-text">
              <h3 className="failed-text">Failed</h3>
              <p className="failed-desc">
                Sorry, your payment was not successful. Please try again.
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
