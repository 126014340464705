import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const uploadSingleFileByPresignedUrl = async (userId, file) => {
  if (!file || !file.name || !file.type) {
    throw new Error("File is required");
  }

  try {
    let file_name = file.name;
    // let file_extension = file_name.split(".").pop();
    // let file_id = uuidv4();

    // let new_file_name = file_name.replace(
    //   new RegExp(`.${file_extension}$`),
    //   `_${file_id}.${file_extension}`
    // );

    let new_file = new File([file], file_name, { type: file.type });

    // Generate presigned URL
    const presignedUrlRes = await generatePreSignedUrl(
      userId,
      file_name,
      file.type,
      "put_object"
    );
    if (!presignedUrlRes || !presignedUrlRes.presigned_url) {
      throw new Error("Presigned URL not found");
    }

    // Upload file to S3
    const uploadFileRes = await axios.put(
      presignedUrlRes.presigned_url,
      new_file,
      {
        headers: {
          "Content-Type": file.type,
        },
      }
    );
    if (!uploadFileRes || uploadFileRes.status !== 200) {
      throw new Error("File upload failed");
    }

    let file_url = presignedUrlRes.presigned_url?.split("?")[0];
    return {
      file_name: file_name,
      s3_file_key: presignedUrlRes.file_key,
      s3_file_url: file_url || "",
    };
  } catch (error) {
    throw error;
  }
};

const generatePreSignedUrl = (
  userId,
  file_name,
  content_type,
  method = "get_object"
) => {
  let uuid = uuidv4();
  let preSignedUrl = axios.post(
    `${process.env.REACT_APP_AI_API_BASE_URL}/fastapi/v1/awsS3/generate-presigned-url`,
    {
      external_user_id: `legittid${userId || "default"}/${uuid}`,
      file_name: file_name,
      content_type: content_type || "application/octet-stream",
      method: method,
    },
    {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AI_API_TOKEN}`,
      },
    }
  );

  return preSignedUrl
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
