import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../pages/Error404";
import NewLandingPage from "../pages/NewLandingPage/index";

function MyRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NewLandingPage />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default MyRoutes;
