import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SuccessLeftImage from "../../assets/images/SuccessLeftImage.svg";

export default function UpgradeSuccess({
  openSuccessPopup,
  setOpenSuccessPopup,
}) {
  const [showMainContent, setShowMainContent] = useState(false);

  useEffect(() => {
    if (openSuccessPopup) {
      // Show GIF container with zoom in effect initially
      setTimeout(() => {
        // Hide GIF container with zoom out effect after 3 seconds
        setShowMainContent(true); // Show main content container with zoom in effect
      }, 3800);
    }
  }, [openSuccessPopup]);

  return (
    <div>
      <Dialog
        open={openSuccessPopup}
        className="email-popup-upgrade success-popup"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ width: "100%", padding: "0" }}>
          <div className="success-popup-content">
            <div
              className={`success-plan-purchase-gif-container ${
                showMainContent
                  ? "hide-success-plan-purchase-gif-animation"
                  : "hide-success-plan-purchase-gif-animation-zoom-in"
              }`}
            >
              <iframe
                src="https://lottie.host/embed/b05b501d-a9c4-4d5b-a0e4-7ad3ea49c333/bmsag4UNoU.json"
                frameBorder="0"
                allowFullScreen
                title="Lottie Animation"
              ></iframe>
            </div>
            <div
              className={`success-popup-main-content-container ${
                showMainContent
                  ? "hide-success-plan-purchase-gif-animation-zoom-in"
                  : "hide-success-plan-purchase-gif-animation"
              }`}
            >
              <div className="success-popup-main-left-content">
                <img
                  src={SuccessLeftImage}
                  alt="Success Left"
                  className="success-popup-main-left-image"
                />
              </div>
              <div className="success-popup-main-right-content">
                <div className="success-popup-main-right-content-title">
                  Now you have access to the
                  <br />
                  <span className="success-popup-content-title-span">
                    Premium plan features
                  </span>
                </div>
                <div className="success-popup-main-right-content-description">
                  You have successfully upgraded your account to the Premium
                  plan. Now you have access to all the premium features. Enjoy
                  🎉
                </div>
                <div className="success-popup-main-right-content-button-container">
                  <div
                    className="common-button"
                    onClick={() => {
                      setOpenSuccessPopup(false);
                    }}
                  >
                    Continue
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
