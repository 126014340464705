import React, { useState, useEffect } from "react";
import "../css/Hero.css";
import CommonButton from "../Components/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

import TextField from "@mui/material/TextField";
import { Radio } from "@mui/material";
import { Button, Drawer } from "antd";

const ESignDetailDrawer = ({
  resetDetails,
  mydocument,
  user,
  loadingPdf,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  signatories_category,
  setSignatories_category,
  isLoading,
  handleEmailChange,
  handleSubmit,
  onClose,
  setOpen,
  open,
}) => {
  const showDrawer = () => {
    setOpen(true);
  };

  console.log({
    user,
    mydocument,
  });

  return (
    <div className="drawer-esign-parent-container">
      {!(user && user.token && mydocument && mydocument.documentId) ? (
        <CommonButton
          label={
            <span className="d-flex align-items-center">
              <AddIcon /> Add Signatory
            </span>
          }
          onClick={showDrawer}
          className="floatingButton"
          variant={"contained-variant"}
        />
      ) : null}

      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        className="drawer-esign-container"
        open={open}
      >
        <div className="right-container-pdf-view-header">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={signatories_category}
              style={{
                width: "100%",
                display: "flex",

                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
              onChange={(e) => {
                setSignatories_category(e.target.value);
              }}
            >
              <div className="self-container">
                <div className="flex items-center justify-start gap-3 w-full">
                  <div className="self-container-icon-container">
                    <img
                      src={require("../assets/icons/self.png")}
                      className="self-icon"
                    />
                  </div>
                  <div className="self-sign-text">
                    <FormControlLabel
                      value="self_only"
                      control={<Radio />}
                      label="Only I need to sign"
                      labelPlacement="start"
                      // disabled={!allowDocumentEdit}
                    />
                  </div>
                </div>
              </div>

              <div className="self-other-container ">
                <div className="flex items-center justify-start gap-3 w-full">
                  <div className="self-container-icon-container">
                    <img
                      src={require("../assets/icons/self-other.png")}
                      className="self-icon"
                    />
                  </div>
                  <div className="self-sign-text">
                    <FormControlLabel
                      value="multiple"
                      control={<Radio />}
                      label="Others need to sign too"
                      // disabled={!allowDocumentEdit}
                      labelPlacement="start"
                    />
                  </div>
                </div>
              </div>

              <div className="other-container">
                <div className="flex items-center justify-start gap-3 w-full">
                  <div className="self-container-icon-container">
                    <img
                      src={require("../assets/icons/other.png")}
                      className="self-icon"
                    />
                  </div>
                  <div className="self-sign-text">
                    <FormControlLabel
                      value="others_only"
                      control={<Radio />}
                      labelPlacement="start"
                      label="Send to others only (I won't sign)"
                      // disabled={!allowDocumentEdit}
                    />
                  </div>
                </div>
              </div>
            </RadioGroup>
          </FormControl>

          <div className="information-container">
            <div className="information-container-title">From:</div>
            <div className="email-container">
              {/* <Input
                  size="large"
                  placeholder="Name"
                  prefix={<UserOutlined />}
                /> */}
              <div className="firstNameLastNameInput">
                <TextField
                  className="emailInput"
                  label="First Name"
                  required
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                  variant="outlined"
                  disabled={isLoading || loadingPdf}
                />
                <TextField
                  className="emailInput"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  label="Last Name"
                  value={lastName}
                  required
                  variant="outlined"
                  disabled={isLoading || loadingPdf}
                />
              </div>
              <TextField
                className="emailInput"
                label="Email Address"
                required
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                disabled={isLoading || loadingPdf}
              />
              {/* <Input
                  size="large"
                  placeholder="Email Address"
                  prefix={<MailOutlined />}
                /> */}
            </div>

            <div className="helper-text-for-mail-input">
              Give your name and email address to let others know who you are.
            </div>
          </div>
          <div className="Button-container">
            <CommonButton
              label="Cancel"
              size="small"
              variant="outlined"
              onClick={() => {
                onClose();
                resetDetails();
              }}
            />
            <CommonButton
              label="Next"
              size="extraLarge"
              variant="contained-variant"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default ESignDetailDrawer;
