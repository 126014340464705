import REACT, { useState } from "react";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { HeroHighlightText, Highlight } from "./HeroHighlightText";

export function HeroHighlightTextDemo() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const scheduleDemo = async () => {
    const { email } = formData;
    if (!validateEmail(email)) {
      Swal.fire("Error!", "Email is invalid!", "error");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://prodapi2.legittai.com/api/notification/sendMail",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData.email,
            senderEmail:
              "akashnigam1507@gmail.com, harshdeep.rapal@legittai.com",
          }),
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );

      if (response.ok) {
        Swal.fire(
          "Thank you!",
          "We have received your mail. Our team will contact you shortly!",
          "success"
        );
        setFormData({
          email: "",
        });
      } else {
        Swal.fire(
          "Error!",
          "Something went wrong while sending email. Try again or email at info@legittai.com!",
          "error"
        );
      }
    } catch (err) {
      console.error("Error:", err);
      Swal.fire(
        "Error!",
        "Something went wrong while sending email. Try again or email at info@legittai.com!",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HeroHighlightText>
      <div
        className="hidden 700sm:inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
        role="alert"
      >
        <span className="text-xs bg-primary-600 rounded-full text-black px-3 py-1.5"></span>{" "}
        <span className="text-sm font-medium">
          Use Free Contract Electronic Signature to Simplify Your Work Flow
        </span>
        <svg
          className="ml-2 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={{
          opacity: 1,
          y: [20, -5, 0],
        }}
        transition={{
          duration: 0.5,
          ease: [0.4, 0.0, 0.2, 1],
        }}
        className="max-w-7xl mx-auto text-left  px-4  532sm:text-center font-[800] text-[1.6rem] 631sm:text-4xl text-gray-900 mb-5 968sm:text-[3rem]"
      >
        <h1>Experience Effortless E-signatures</h1>
        <div className="mt-[5px] 532sm:mt-[10px] 631sm:mt-[28px]">
          <Highlight className="text-black  px-4  dark:text-white">
            Upload {"->"} Sign {"->"} Send.
          </Highlight>
        </div>
      </motion.div>
      <p className="mt-[15px] mx-auto max-w-screen-xl px-4  text-[16px] font-normal text-left 532sm:text-center text-[#262626] lg:text-[18px] sm:px-16 xl:px-48 ">
        World's only AI-enabled e-signatures to track documents proactively.
      </p>
    </HeroHighlightText>
  );
}
