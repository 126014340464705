const { ClassValue, clsx } = require("clsx");
const { twMerge } = require("tailwind-merge");

function TextHighlight(...inputs) {
  return twMerge(clsx(inputs));
}

module.exports = {
  TextHighlight,
};
