import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { signup } from "../../services/AccountServices";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useConfettiTrigger } from "../Confetti";

const AcceptCouponFromUser = ({
  isPopupOpen,
  setIsPopupOpen,
  openCouponPopup,
  createCheckoutSession, // Added as a prop
  selectedPlan,
  closePopup,
  selectedPlanPeriod,
  isLoading,
}) => {
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [firstName, setFirstName] = useState(""); // State for first name
  const [lastName, setLastName] = useState(""); // State for last name
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleConfettiTrigger = useConfettiTrigger();
  const handleSignup = async () => {
    if (!email || !firstName || !lastName || !couponCode) {
      enqueueSnackbar("Please fill in all required fields!", {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    try {
      const result = await signup(
        email,
        "",
        false,
        "",
        "",
        firstName,
        lastName,
        couponCode
      );
      console.log("Signup Result:", result);
      if (result.error) {
        enqueueSnackbar(
          result.msg || "Invalid coupon code. Please try again.",
          { variant: "error" }
        );

        setEmail("");
        setFirstName("");
        setLastName("");
        setCouponCode("");

        return;
      }
      setEmail("");
      setFirstName("");
      setLastName("");
      setCouponCode("");
      handleConfettiTrigger();
      enqueueSnackbar(`Plan Purchased successfully!`, { variant: "success" });
      closePopup();
      // Redirect to the specified URL
      const url = `${process.env.REACT_APP_LEGITT_BASE_URL}/${result.storeName}/dashboard?access_token=${result.token}`;

      window.open(url, "_blank");
    } catch (error) {
      setEmail("");
      setFirstName("");
      setLastName("");
      setCouponCode("");
      console.error("Error while signing up:", error);
      enqueueSnackbar("Something went wrong, please try again!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBuyClick = () => {
    // Call the createCheckoutSession function with selected plan and period
    createCheckoutSession(selectedPlan, selectedPlanPeriod);
  };

  return (
    <Dialog
      className="coupon-code-popup-dialog"
      open={isPopupOpen}
      onClose={closePopup}
    >
      <DialogTitle className="flex items-center justify-between gap-2">
        <div className="text-[14px] flex items-center gap-1">
          Your selected plan is{" "}
          <span className="inline-flex items-center gap-x-1 whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-900 ring-emerald-600/30 dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-emerald-400/20">
            {selectedPlan}
          </span>
        </div>
        <div>
          <IconButton className="float-right" onClick={closePopup}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="flex gap-2 flex-col">
          {/* Left side for inputs */}
          <div className="bg-[#F5F4F5] rounded-lg p-3">
            <div className="text-center">
              <span className="text-center text-[#000] text-[14px]">
                Enter your details and coupon code, if you have 100% off coupon.
              </span>
              <div className="grid grid-cols-1 610sm:grid-cols-2 gap-2 mt-2">
                <TextField
                  autoFocus
                  margin="dense"
                  label="First Name"
                  type="text"
                  fullWidth
                  className="bg-[#fff] coupon-code-form-text-fields"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Last Name"
                  type="text"
                  className="bg-[#fff] coupon-code-form-text-fields"
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Email Address"
                  type="email"
                  className="bg-[#fff] coupon-code-form-text-fields"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Coupon Code"
                  type="text"
                  fullWidth
                  variant="outlined"
                  className="bg-[#fff] coupon-code-form-text-fields"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
              </div>
              <div className="flex justify-center items-center gap-2 flex-wrap mt-2">
                <button
                  className="button-outline"
                  onClick={handleSignup}
                  color="primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <CircularProgress color="inherit" size={20} />
                    </>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2 flex-wrap mt-2">
            <span className="text-center text-gray-500">or</span>
          </div>

          {/* Right side for payment option */}
          <div className="flex flex-col justify-center items-center h-full">
            <h2 className="text-lg font-extrabold text-center mb-2">
              Ready to Pay?
            </h2>
            <p className="text-center text-[#000] text-[14px]">
              Proceed with your selected plan to complete the payment.
            </p>
            <button
              className="common-button mt-4 cursor-pointer"
              onClick={createCheckoutSession}
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Buy Now"
              )}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptCouponFromUser;
