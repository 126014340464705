import React from "react";
import { Link } from "react-router-dom";
import "../css/404.css";

function Error404() {
  const storeName = localStorage.getItem("storeName");
  return (
    <>
      <section class="page_404-page-main-container">
        <div class="container">
          <div class="page_404-page-four_zero_four_bg">
            <h1 class="text-center ">404</h1>
          </div>
          <div class="page_404-page-contant_box_404">
            <h3 class="h2">Look like you're lost</h3>
            <p>the page you are looking for not available!</p>
            <div className="back-to-dashboard-404-btn-container">
              <Link to="/">
                <div class="common-button">Back to Landing Page</div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Error404;
