import React, { useCallback, useEffect, useState } from "react";

const CountDownTimer = () => {
  const [countDownTime, setCountDownTime] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const dealDates = [
    {
      name: "Black Friday",
      endDate: new Date("November 29, 2024 23:59:59").getTime(),
    },
    {
      name: "Cyber Monday",
      endDate: new Date("December 2, 2024 23:59:59").getTime(),
    },
    {
      name: "Year End",
      endDate: new Date("December 20, 2024 23:59:59").getTime(),
    },
    {
      name: "New Year",
      endDate: new Date("January 2, 2025 23:59:59").getTime(),
    },
  ];

  const [currentDealIndex, setCurrentDealIndex] = useState(0);

  const getTimeDifference = (endDate: number) => {
    const currentTime = new Date().getTime();
    const timeDifference = endDate - currentTime;

    if (timeDifference < 0) {
      return null;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (60 * 60 * 1000)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);

    return {
      days: days >= 10 ? `${days}` : `0${days}`,
      hours: hours >= 10 ? `${hours}` : `0${hours}`,
      minutes: minutes >= 10 ? `${minutes}` : `0${minutes}`,
      seconds: seconds >= 10 ? `${seconds}` : `0${seconds}`,
    };
  };

  const startCountDown = useCallback(() => {
    const interval = setInterval(() => {
      const deal = dealDates[currentDealIndex];
      if (!deal) {
        clearInterval(interval);
        return;
      }

      const timeRemaining = getTimeDifference(deal.endDate);

      if (!timeRemaining) {
        clearInterval(interval);
        setCurrentDealIndex((prevIndex) => prevIndex + 1); // Move to next deal
      } else {
        setCountDownTime(timeRemaining);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount or deal change
  }, [currentDealIndex, dealDates]);

  useEffect(() => {
    startCountDown();
  }, [startCountDown]);

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center w-full h-full gap-8 sm:gap-16">
        <div className="flex justify-center items-center gap-1">
          <div className="flex flex-col items-center justify-center gap-2 relative">
            <div className="h-8 w-8 sm:w-12 sm:h-12 flex justify-center items-center bg-[#e3e3ec] rounded-lg text-center">
              <span className="text-xl sm:text-2xl font-semibold text-[#4b4c4d] text-center">
                {countDownTime?.days}
              </span>
            </div>
          </div>
          <span className="text-2xl font-semibold text-[#a5b4fc]">:</span>
          <div className="flex flex-col items-center justify-center gap-2 relative">
            <div className="h-8 w-8 sm:w-12 sm:h-12 flex justify-center items-center bg-[#e3e3ec] rounded-lg text-center">
              <span className="text-xl sm:text-2xl font-semibold text-[#4b4c4d]">
                {countDownTime?.hours}
              </span>
            </div>
          </div>
          <span className="text-2xl font-semibold text-[#a5b4fc]">:</span>
          <div className="flex flex-col items-center justify-center gap-2 relative">
            <div className="h-8 w-8 sm:w-12 sm:h-12 flex justify-center items-center bg-[#e3e3ec] rounded-lg text-center">
              <span className="text-xl sm:text-2xl font-semibold text-[#4b4c4d]">
                {countDownTime?.minutes}
              </span>
            </div>
          </div>
          <span className="text-2xl font-semibold text-[#a5b4fc]">:</span>
          <div className="flex flex-col items-center justify-center gap-2 relative">
            <div className="h-8 w-8  sm:w-12 sm:h-12 flex justify-center items-center bg-[#e3e3ec] rounded-lg text-center">
              <span className="text-xl sm:text-2xl font-semibold text-[#4b4c4d]">
                {countDownTime?.seconds}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDownTimer;
