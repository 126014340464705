import React, { useEffect, useState } from "react";

import NewLandingPageHeroSection from "./NewLandingPageHeroSection";
export default function NewLandingPage() {
  return (
    <div>
      <NewLandingPageHeroSection />
    </div>
  );
}
