import { Typography, Box, Grid } from "@mui/material";
import { Star, FormatQuote } from "@mui/icons-material";

const testimonials = [
  {
    id: 1,
    name: "Michael Brown",
    position: "VP of Sales, GreenTech Solutions",
    text: "Legitt AI has completely transformed the way we handle contracts. The electronic signature feature is incredibly intuitive and easy to use, making the signing process much faster. It's a game changer for our small business!",
    rating: 5,
  },
  {
    id: 2,
    name: "Sophia Adams",
    position: "Director, TechConnect Global",
    text: "I’ve used several e-signature tools, but Legitt AI stands out due to its seamless integration with other tools we use. It's efficient, secure, and simple to navigate. Highly recommend!",
    rating: 3.5,
  },
  {
    id: 3,
    name: "Thomas Harris",
    position: "Legal Advisor, Summit Law Group",
    text: "The security features provided by Legitt AI’s electronic signature are top-notch. We were able to ensure that all of our documents are signed with confidence, knowing the system is compliant and protected.",
    rating: 5,
  },
];

const TestimonialSection = () => {
  const TestimonialCard = ({ testimonial }) => (
    <Box
      className="bg-white rounded-3xl p-8 mx-6 relative shadow-lg w-full"
      sx={{
        maxWidth: 400, // Set a max width for the card
        mx: "auto", // Center the card
      }}
    >
      <FormatQuote className="absolute text-gray-300 top-4 left-4 transform -scale-x-100" />
      <div className="flex flex-col items-center">
        <p className="text-center text-gray-700 mb-6">{testimonial.text}</p>
        <div className="flex items-center mb-2">
          {[...Array(5)].map((_, i) => (
            <Star
              key={i}
              className={`w-5 h-5 ${
                i < Math.floor(testimonial.rating)
                  ? "text-yellow-400"
                  : i < Math.ceil(testimonial.rating)
                  ? "text-yellow-400" // Half star condition
                  : "text-gray-300"
              }`}
            />
          ))}
        </div>
        <h3 className="font-bold text-xl mb-1">{testimonial.name}</h3>
        <p className="text-gray-600">{testimonial.position}</p>
      </div>
      <FormatQuote className="absolute text-gray-300 bottom-4 right-4" />
    </Box>
  );

  return (
    <Box className="w-full max-w-6xl mx-auto px-4 py-4 my-16">
      <Typography
        variant="h3"
        component="h2"
        className="text-center my-[20px] font-extrabold"
      >
        What Our Clients Are Saying
      </Typography>
      <Box className="py-6">
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
          sx={{
            // Adjust spacing based on screen size
            "@media (max-width: 600px)": {
              flexDirection: "column", // Stack on small screens
            },
          }}
        >
          {testimonials.map((testimonial) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={testimonial.id}
              sx={{
                display: "flex",
                justifyContent: "center", // Center the cards
              }}
            >
              <TestimonialCard testimonial={testimonial} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TestimonialSection;
