import "../css/Hero.css";

const pdfjsLib = window["pdfjs-dist/build/pdf"];

export const extractPdfPagesFromFile = async (
  file,
  componentId,
  setLoadingPdf,
  enqueueSnackbar
) => {
  return new Promise((resolve, reject) => {
    console.log({ file });
    let fileReader = new FileReader();
    let pageHTML = null;
    let isError = false;
    if (componentId) {
      pageHTML = document.getElementById(componentId);
    } else {
      pageHTML = document.createElement("div");
    }

    fileReader.onload = async function (event) {
      try {
        let arrayBuffer = event.target.result;
        console.log({ arrayBuffer });
        if (arrayBuffer) {
          isError = await renderPDF(
            arrayBuffer,
            pageHTML,
            setLoadingPdf,
            enqueueSnackbar
          );
        } else {
          isError = true;
          enqueueSnackbar("Please upload valid PDF file", {
            variant: "error",
          });
        }
      } catch (error) {
        console.log({ error });
        isError = true;
        setLoadingPdf(false);
        enqueueSnackbar("Please upload valid PDF file", {
          variant: "error",
        });
      } finally {
        console.log({ isError });
        if (isError) {
          setLoadingPdf(false);
          reject(false);
        } else {
          resolve(pageHTML);
        }
      }
    };

    fileReader.onerror = function (event) {
      console.log({ event });
      setLoadingPdf(false);
      enqueueSnackbar("Please upload valid PDF file", {
        variant: "error",
      });
      reject(false);
    };

    fileReader.readAsArrayBuffer(file);
  }).catch((error) => {
    console.log({ error });
    setLoadingPdf(false);
    return false;
  });
};

async function renderPDF(
  arrayBuffer,
  pageHTML,
  setLoadingPdf,
  enqueueSnackbar
) {
  let isError = false;
  try {
    console.log({ pdfjsLib });

    await pdfjsLib
      .getDocument({ data: arrayBuffer })
      .promise.then(function (pdf) {
        var numPages = pdf.numPages;
        console.log({ numPages });
        if (numPages) {
          pageHTML.innerHTML = "";
          console.log({ pageHTML });
          let currentPage = 1;
          renderNextPage(pdf, numPages, pageHTML, currentPage, setLoadingPdf);
        } else {
          enqueueSnackbar("Please upload valid PDF file", {
            variant: "error",
          });
          isError = true;
          return false;
        }
      })
      .catch(function (error) {
        isError = true;
        console.log({ error });
        setLoadingPdf(false);
        enqueueSnackbar("Please upload valid PDF file", {
          variant: "error",
        });
        return false;
      });
  } catch (error) {
    isError = true;
    console.log({ error });
    setLoadingPdf(false);
    enqueueSnackbar("Please upload valid PDF file", {
      variant: "error",
    });
    return;
  }
  return isError;
}

function renderNextPage(pdf, numPages, pageHTML, currentPage, setLoadingPdf) {
  if (currentPage <= numPages) {
    pdf.getPage(currentPage).then(function (page) {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
        qaulity: 0.9,
      };

      // Render the page on the canvas
      page.render(renderContext).promise.then(function () {
        const pageData = canvas.toDataURL("image/png", 0.9);
        // Alternatively, you can use `canvas.toDataURL('image/jpeg', 0.8)` to get JPEG format with 80% quality

        // Clean up resources
        canvas.remove();

        const img = document.createElement("img");
        img.classList.add("legitt-custom-pdf-page-image");
        img.src = pageData;

        pageHTML.appendChild(img);

        currentPage++;
        renderNextPage(pdf, numPages, pageHTML, currentPage, setLoadingPdf); // Render the next page
      });
    });
  } else {
    console.log("done");
    setLoadingPdf(false);
  }
}
