import Axios from 'axios';
const IPAddress =`${process.env.REACT_APP_NODE_APIS_BASE_URL}/apis/auth/`;

const AccountServices = {
  signupnew: IPAddress + "signupnew",
};

export const signup = async (
  email,
  password,
  googleSignIn,
  templateId,
  captcha,
  firstName,
  lastName,
  couponCode
) => {
  try {
    const response = await Axios.post(AccountServices.signupnew, {
      email,
      password,
      isGoogleSignIn: googleSignIn,
      templateId,
      captcha : true,
      firstName: firstName || "",
      lastName: lastName || "",
      couponCode: couponCode || "",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
