import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import {
  TextField,
  Box,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import Swal from "sweetalert2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Close } from "@mui/icons-material";

const Popup = ({ isOpen, onClose, formData, handleCloseCalendlyPopup }) => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("Profile page viewed"),
    onDateAndTimeSelected: () => console.log("Date and time selected"),
    onEventTypeViewed: () => console.log("Event type viewed"),
    onEventScheduled: (e) => console.log("Event scheduled:", e.data.payload),
    onPageHeightResize: (e) =>
      console.log("Page height resized:", e.data.payload.height),
  });

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseCalendlyPopup}
      fullWidth
      maxWidth="md"
      className="demo-popup-calendly-schedule"
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-[18px] text-[#000] font-semibold">
              We have received your demo request successfully.
            </div>
            <div className="text-[14px] font-medium">
              Book your own time slot.
            </div>
          </div>
          <IconButton onClick={handleCloseCalendlyPopup}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <InlineWidget
          url="https://calendly.com/info-f2ye/schedule-a-demo?hide_event_type_details=1&hide_gdpr_banner=1"
          prefill={{
            name: `${formData.firstname} ${formData.lastname}`,
            email: formData.email,
          }}
          styles={{
            minWidth: "520px",
            height: "700px",
            borderRadius: "50px",
            zIndex: 60,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const ScheduleDemo = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyName: "",
    phone: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const sectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleCloseCalendlyPopup = () => {
    setIsPopupOpen(false);
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      companyName: "",
      phone: "",
    });
  };

  const handleScroll = () => {
    if (sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const isInView = top < window.innerHeight * 0.5 && top >= 0;
      setIsScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value ?? "" });
  };

  const scheduleDemo = async () => {
    const { email, phone } = formData;
    setIsLoading(true);
    if (!validateEmail(email)) {
      Swal.fire("Error!", "Email is invalid!", "error");
      setIsLoading(false);
      return;
    }
    if (!isValidPhoneNumber(phone)) {
      Swal.fire("Error!", "Phone number is invalid!", "error");
      setIsLoading(false);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://prodapi2.legittai.com/api/notification/sendMail",
        {
          method: "POST",
          body: JSON.stringify({
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            phone: formData.phone,
            companyName: formData.companyName,
            senderEmail:
              "akashnigam1507@gmail.com, harshdeep.rapal@legittai.com",
          }),
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );

      if (response.ok) {
        setIsSubmitting(false);
        setIsPopupOpen(true);
        setIsLoading(false);
      } else {
        Swal.fire(
          "Error!",
          "Something went wrong while sending email. Try again or email at info@legittai.com!",
          "error"
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsLoading(false);
      Swal.fire(
        "Error!",
        "Something went wrong while sending email. Try again or email at info@legittai.com!",
        "error"
      );
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="px-0">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={`form-container flex flex-col gap-2 ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <Box className="grid grid-cols-1 gap-2 513sm:grid-cols-2">
          <TextField
            required
            fullWidth
            className="demo-form-input-fields"
            id="firstname"
            label="First Name"
            variant="outlined"
            value={formData.firstname}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            className="demo-form-input-fields"
            id="lastname"
            label="Last Name"
            variant="outlined"
            value={formData.lastname}
            onChange={handleChange}
          />
        </Box>
        <TextField
          required
          fullWidth
          className="demo-form-input-fields"
          id="companyName"
          label="Company"
          variant="outlined"
          value={formData.companyName}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          className="demo-form-input-fields"
          id="email"
          label="Email Address"
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />

        <PhoneInput
          className="w-full bg-[#fff] py-[15px] px-[11px] demo-contact-number-text-field"
          placeholder="Enter phone number"
          value={formData.phone}
          onChange={handlePhoneChange}
          defaultCountry="IN"
          required
        />
        <div className="flex justify-center items-center mt-[10px]">
          <button
            className="common-button"
            onClick={scheduleDemo}
            disabled={isSubmitting || isLoading}
          >
            {isSubmitting || isLoading ? (
              <div>
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              "Get A Demo"
            )}
          </button>
        </div>
      </Box>
      <Popup
        isOpen={isPopupOpen}
        onClose={handleCloseCalendlyPopup}
        formData={formData}
        handleCloseCalendlyPopup={handleCloseCalendlyPopup}
      />
    </Container>
  );
};

const Demo = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const sectionRef = useRef(null);

  const handleScroll = () => {
    if (sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const isInView = top < window.innerHeight * 0.5 && top >= 0;
      setIsScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex  flex-wrap mb-[100px] lg:flex-nowrap ${
          isScrolled ? "px-[6%]" : "px-0"
        } transition-all duration-500 ease-in-out justify-center items-center rounded-lg gap-6`}
        ref={sectionRef}
      >
        <div className="w-full lg:w-[100%]">
          <div
            className={`relative isolate overflow-hidden ${
              isScrolled ? "bg-gray-900 text-white" : "bg-[#fff3f3] text-black"
            } py-16 sm:py-24 rounded-[50px] transition-all duration-300 ease-in-out`}
          >
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="max-w-full lg:max-w-lg">
                  <h2
                    className={`text-xl mb-[20px] font-bold tracking-tight ${
                      isScrolled ? "text-white" : "text-black"
                    } sm:text-2xl`}
                  >
                    Schedule a Demo & Discussion with an Expert
                  </h2>

                  <ScheduleDemo />
                </div>
                <dl className="">
                  <div className="w-full text-left">
                    <h1 className="font-bold text-[32px] sm:text-[40px] lg:text-[48px] rounded-lg">
                      Talk to our experts
                    </h1>
                    <p className="text-[16px] sm:text-[18px] lg:text-[20px] font-bold mt-4">
                      Schedule a personalized consultation
                    </p>
                    <p className="text-[14px] sm:text-[16px] lg:text-[18px] mt-2">
                      Explore how to use our contract lifecycle management tools
                      and solutions. How this AI-based contract management
                      software aligns with your business needs. Take a guided
                      walk through of the contract management system and find
                      how to gain insights tailored to your specific goals and
                      challenges.
                    </p>
                  </div>
                </dl>
              </div>
            </div>
            <div
              className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
              aria-hidden="true"
            >
              <div
                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
